// Server calls

export interface Option {
    location: string;
    data: Series[];
    xAxis: string[];
}

export interface Series {
    name: string;
    type?: string;
    data: string[];
}

export interface Data {
    eco2: number;
    tvoc: number;
    temperature: number;
    humidity: number;
    timestamp: any;
    sample_date: any;
    current_mode: number;
}

const serverURI = 'http://www.phylo.ca/';

export const getLatestRecord = (currentDevice: string) => fetch(serverURI + 'getLatestRecord.php?location=' + currentDevice)
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
        console.error(error);
    });

export const getRecords = (currentDevice: string, days?: number, series?: string[]) => {
    const serieQuery = series ? '&series=' + series.join(",") : "";
    return fetch(serverURI + 'getRecords.php?location=' + currentDevice + '&days=' + days + serieQuery)
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
        console.error(error);
    })
};

export const getVentilationBreakdown = (currentDevice: string, days?: number) => fetch(serverURI + 'getVentilationBreakdown.php?location=' + currentDevice + '&days=' + days)
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
        console.error(error);
    });

export const getControllersList = () => fetch(serverURI + 'getControllers.php')
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
        console.error(error);
    });
