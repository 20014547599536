import merge from 'lodash/merge';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader, Box, CircularProgress } from '@mui/material';
// components
import { BaseOptionChart } from '../../../../components/chart';
import { getRecords, Option, Series } from "../../../../utils/services";
import { useTheme } from "@mui/material/styles";
import AirQualityGraph from "./AirQualityGraph";

// ----------------------------------------------------------------------

interface Props {
    currentDevice: string;
    series: string[];
    duration?: number;
}

export default function AirQualitySeries(props: Props) {
    const theme = useTheme();
    const [chartData, setChartData] = useState<Option[]>([]);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        fetchRecords();
    }, [props.currentDevice, props.duration]);

    const fetchRecords = () => {
        if (props.duration !== undefined) {
            setIsLoading(true);
            getRecords(props.currentDevice, props.duration, props.series).then((json) => {
                if (json.xAxis !== null) {
                    setChartData(Array(json).filter((item: Option) => (item && item.location === props.currentDevice.toLowerCase())));
                    setIsLoading(false);
                }
            });
        }
    }

    const chartOptions = merge(BaseOptionChart(), {
        xaxis: {
            categories: chartData[0] && chartData[0].xAxis,
        },
        tooltip: {
            theme: "dark"
        },
        fill: { type: ['gradient'] },
        chart: {
            animations: {
                enabled: false,
            }
        }
    });

    if (chartData === [])
        return null;

    return (
        <>
            <Card sx={{ mb: 2 }}>
                <CardHeader
                    title={`${props.series} - last ${props.duration} days`}
                    subheader=""
                />

                {isLoading ?
                    <CircularProgress/>
                    :
                    <Box sx={{ mt: 3, mx: 3 }} dir="ltr">
                        {chartData[0] && <AirQualityGraph
                            series={chartData[0].data
                                .filter((s: Series) => props.series.includes(s.name))
                                .map((s: Series) => ({ ...s, type: "area" }))
                            }
                            options={{
                                ...chartOptions,
                                colors: [
                                    theme.palette.primary.main,
                                ]
                            }} height={200}/>}
                    </Box>
                }
            </Card>
        </>
    );
}
