// @mui
import { Box, Container, FormControl, Grid, InputLabel } from '@mui/material';
import { useTheme } from "@mui/material/styles";
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
// sections
import AirQuality from "../../sections/@dashboard/general/app/AirQuality";
import { LevelsIndicator } from "../../sections/@dashboard/general/app";
import { useEffect, useState } from "react";
import VentilationBreakdown from "../../sections/@dashboard/general/app/VentilationBreakdown";
import DeviceSelector from "../../sections/@dashboard/general/app/DeviceSelector";
import { Data, getLatestRecord, Series } from "../../utils/services";
import DurationSelector from "../../sections/@dashboard/general/app/DurationSelector";
import AirQualityGroupedByDay from "../../sections/@dashboard/general/app/AirQualityGroupedByDay";
import { modesMap } from "./Overview";

// ----------------------------------------------------------------------

export default function DetailedView() {
    const theme = useTheme();
    const { themeStretch } = useSettings();
    const [currentDevice, setCurrentDevice] = useState<string>("");
    const [duration, setDuration] = useState<number>(1);

    const onChangeCurrentDevice = (selectedDevice: string) => {
        setCurrentDevice(selectedDevice);
    }

    const onChangeDuration = (duration: number) => {
        setDuration(duration);
    }

    return (
        <Page title="General: App">
            <Container maxWidth={themeStretch ? false : 'xl'}>
                <Grid container spacing={3}>

                    <Grid item xs={12} md={12}>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 0 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <DeviceSelector onChangeCurrentDevice={onChangeCurrentDevice}/>
                        </Box>
                    </Grid>

                    {currentDevice !== "" &&
                    <>
                        <Grid item xs={12} md={12} sx={{ mt: 2 }}>
                            <Box sx={{ display: 'inline' }}>
                                <DurationSelector onChangeDuration={onChangeDuration}/>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <AirQuality currentDevice={currentDevice} duration={duration}/>
                        </Grid>
                    </>}
                </Grid>
            </Container>
        </Page>
    )
        ;
}