import { useState } from 'react';
import {
    FormControl,
    TextField
} from '@mui/material';

// ----------------------------------------------------------------------

interface Props {
    onChangeDuration: (duration: number) => void;
}

export default function DurationSelector(props: Props) {
    const [duration, setDuration] = useState<number>(1);

    const handleChange = (event: any) => {
        const {
            target: { value },
        } = event;
        setDuration(value);
        props.onChangeDuration(value);
    };

    return (
        <FormControl sx={{ width: 300 }} size="small">
            <TextField
                label="History in days"
                id="outlined-size-small"
                size="small"
                value={duration}
                onChange={handleChange}
            />
        </FormControl>
    );
}
