import merge from 'lodash/merge';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader, Box, CircularProgress } from '@mui/material';
// components
import { BaseOptionChart } from '../../../../components/chart';
import { getRecords, Option } from "../../../../utils/services";

// ----------------------------------------------------------------------

interface Props {
    currentDevice: string;
    duration: number;
}

export default function AirQuality(props: Props) {
    const [chartData, setChartData] = useState<Option[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchRecords();
    }, [props.currentDevice, props.duration]);

    const fetchRecords = () => {
        setIsLoading(true);
        getRecords(props.currentDevice, props.duration).then((json) => {
            if (json.xAxis !== null) {
                setChartData(Array(json));
                setIsLoading(false);
            }
            console.log(Array(json));
        });
    }

    const chartOptions = merge(BaseOptionChart(), {
        xaxis: {
            categories: chartData[0] && chartData[0].xAxis,
        },
        tooltip: {
            theme: "dark"
        },
        yaxis: [
            {
                seriesName: 'CO2'
            },
            {
                seriesName: 'VOC'
            },
            {
                seriesName: 'Temperature'
            },
            {
                seriesName: 'Humidity'
            },
            {
                seriesName: 'Mode'
            }
        ]
    });

    if (chartData === [])
        return null;

    return (
        <Card>
            <CardHeader
                title={`Air Quality - last ${props.duration} days`}
                subheader=""
            />

            {isLoading ?
                <CircularProgress/>
                : <>{chartData.map((item: Option) => (
                    <Box key={item.location} sx={{ mt: 3, mx: 3 }} dir="ltr">
                        {item && item.location === props.currentDevice.toLowerCase() && (
                            <ReactApexChart type="line" series={item.data} options={chartOptions} height={500}/>
                        )}
                    </Box>
                ))}</>
            }
        </Card>
    );
}
