// @mui
import { Box, Card, Typography } from '@mui/material';
// utils
import { fNumber } from '../../../../utils/formatNumber';

// ----------------------------------------------------------------------

type Props = {
  title: string;
  total: number;
  suffix: string;
};

export default function LevelsIndicator({ title, total, suffix }: Props) {

  return (
    <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle2">{title}</Typography>
        <Typography variant="h3">{fNumber(total)} {suffix}</Typography>
      </Box>
    </Card>
  );
}
