import { useEffect, useState } from 'react';
// @mui
import { Theme, useTheme } from '@mui/material/styles';
import {
    FormControl,
    InputLabel,
    Select,
    OutlinedInput, MenuItem
} from '@mui/material';
import { getControllersList, getRecords } from "../../../../utils/services";

// ----------------------------------------------------------------------

interface Props {
    onChangeCurrentDevice: (selectedDevice: string) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name: string, personName: string[], theme: Theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function DeviceSelector(props: Props) {
    const theme = useTheme();
    const [controllersList, setControllersList] = useState<string[]>([]);
    const [currentDevice, setCurrentDevice] = useState<string[]>([]);

    useEffect(() => {
        fetchControllers();
        const selectedDevice = window.localStorage.getItem('selectedDevice');
        if (selectedDevice !== null) {
            console.log("Setting current device from local storage");
            setCurrentDevice([selectedDevice]);
            props.onChangeCurrentDevice(selectedDevice.toLowerCase());
        }
    }, []);

    const fetchControllers = () => {
        getControllersList().then((json) => {
            setControllersList(json);
        });
    }

    const handleChange = (event: any) => {
        const {
            target: { value },
        } = event;
        setCurrentDevice(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        props.onChangeCurrentDevice(value.toLowerCase());
        window.localStorage.setItem('selectedDevice', value.toLowerCase());
    };

    return (
        <FormControl sx={{ width: 300 }} size="small">
            <InputLabel id="demo-multiple-name-label">Device</InputLabel>
            <Select
                labelId="device-label"
                id="device-id"
                value={currentDevice}
                onChange={handleChange}
                input={<OutlinedInput label="Device"/>}
                MenuProps={MenuProps}
            >
                {controllersList.map((name) => (
                    <MenuItem
                        key={name}
                        value={name}
                        style={getStyles(name, currentDevice, theme)}
                    >
                        {name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
