import ReactApexChart from 'react-apexcharts';
// components
import { ApexOptions } from "apexcharts";

// ----------------------------------------------------------------------

interface Props {
    options: ApexOptions;
    series: any[];
    height: number;
}

export default function AirQualityGraph(props: Props) {

    return (
        <ReactApexChart type="line"
                        series={props.series}
                        options={props.options}
                        height={props.height}/>
    );
}
