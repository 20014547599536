// @mui
import { Box, Container, FormControl, Grid, InputLabel } from '@mui/material';
import { useTheme } from "@mui/material/styles";
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
// sections
import { LevelsIndicator } from "../../sections/@dashboard/general/app";
import { useEffect, useState } from "react";
import VentilationBreakdown from "../../sections/@dashboard/general/app/VentilationBreakdown";
import DeviceSelector from "../../sections/@dashboard/general/app/DeviceSelector";
import { Data, getLatestRecord, Series } from "../../utils/services";
import DurationSelector from "../../sections/@dashboard/general/app/DurationSelector";
import AirQualityGroupedByDay from "../../sections/@dashboard/general/app/AirQualityGroupedByDay";
import AirQualitySeries from "../../sections/@dashboard/general/app/AirQualitySeries";

// ----------------------------------------------------------------------

export enum modesMap {
    "Off",
    "Recirc",
    "Min",
    "Max",
    "Timer",
    "Auto",
};

export default function Overview() {
    const theme = useTheme();
    const { themeStretch } = useSettings();
    const [currentDevice, setCurrentDevice] = useState<string>("");
    const [duration, setDuration] = useState<number>(1);
    const [latestRecord, setLatestRecord] = useState<Data>();

    let filterTimeout: any;

    useEffect(() => {
        fetchLatestRecord();
        const interval = setInterval(() => {
            fetchLatestRecord();
        }, 30000);

        return () => clearInterval(interval);
    }, [currentDevice, duration]);

    const fetchLatestRecord = () => {
        getLatestRecord(currentDevice).then((json) => {
            console.log("Latest Record", json);
            setLatestRecord(json);
        })
    }

    const onChangeCurrentDevice = (selectedDevice: string) => {
        setCurrentDevice(selectedDevice);
    }

    const onChangeDuration = (duration: number) => {
        clearTimeout(filterTimeout);
        filterTimeout = setTimeout(() => {
            setDuration(duration);
        }, 300);
    }

    return (
        <Page title="General: App">
            <Container maxWidth={themeStretch ? false : 'xl'}>
                <Grid container spacing={3}>

                    <Grid item xs={12} md={12}>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 0 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <DeviceSelector onChangeCurrentDevice={onChangeCurrentDevice}/>
                            {currentDevice !== "" && latestRecord &&
                            <FormControl sx={{ width: 300 }} size="small">
                                <InputLabel>Current Mode: <strong style={{ textTransform: 'uppercase', color:theme.palette.primary.main }}>{modesMap[latestRecord?.current_mode]}</strong></InputLabel>
                            </FormControl>
                            }
                        </Box>
                    </Grid>

                    {currentDevice !== "" && latestRecord &&
                    <>
                        <Grid item xs={6} md={3}>
                            <LevelsIndicator
                                title="CO2 levels"
                                total={latestRecord.eco2}
                                suffix="ppm"
                            />
                        </Grid>

                        <Grid item xs={6} md={3}>
                            <LevelsIndicator
                                title="VOC levels"
                                total={latestRecord.tvoc}
                                suffix="ppb"
                            />
                        </Grid>

                        <Grid item xs={6} md={3}>
                            <LevelsIndicator
                                title="Temperature"
                                total={latestRecord.temperature}
                                suffix="&#8451;"
                            />
                        </Grid>

                        <Grid item xs={6} md={3}>
                            <LevelsIndicator
                                title="Humidity"
                                total={latestRecord.humidity}
                                suffix="%RH"
                            />
                        </Grid>

                        <Grid item xs={12} md={12} sx={{ mt: 2 }}>
                            <Box sx={{ display: 'inline' }}>
                                <DurationSelector onChangeDuration={onChangeDuration}/>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={8}>
                            <AirQualityGroupedByDay series={["CO2", "VOC", "Temperature", "Humidity"]} currentDevice={currentDevice} duration={duration}/>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <VentilationBreakdown currentDevice={currentDevice} duration={duration}/>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <AirQualitySeries series={["CO2"]} currentDevice={currentDevice} duration={duration}/>
                            <AirQualitySeries series={["VOC"]} currentDevice={currentDevice} duration={duration}/>
                            <AirQualitySeries series={["Temperature"]} currentDevice={currentDevice} duration={duration}/>
                            <AirQualitySeries series={["Humidity"]} currentDevice={currentDevice} duration={duration}/>
                        </Grid>
                    </>}
                </Grid>
            </Container>
        </Page>
    );
}