import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
    disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {

    const logo = (
        <Box sx={{ width: 40, height: 40, ...sx }}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 57.15 18.455"
                height={69.752}
                width={215.999}
            >
                <text
                    y={84.343}
                    x={-34.746}
                    style={{
                        fontSize: "10.0394px",
                        lineHeight: 1.25,
                        fontFamily: "sans-serif",
                        fill: "#00ab55",
                        fillOpacity: 1,
                        strokeWidth: 0.150591,
                        textDecoration: "none",
                    }}
                    xmlSpace="preserve"
                    transform="translate(52.5 -74.714)"
                >
                    <tspan
                        style={{
                            fontStyle: "normal",
                            fontVariant: "normal",
                            fontWeight: 700,
                            fontStretch: "normal",
                            fontSize: "10.0394px",
                            fontFamily: "'Montserrat Alternates'",
                            fill: "#00ab55",
                            fillOpacity: 1,
                            strokeWidth: 0.150591,
                            textDecoration: "none",
                        }}
                        y={84.343}
                        x={-34.746}
                    >
                        {"PHYLO"}
                    </tspan>
                </text>
                <text
                    y={89.965}
                    x={-33.743}
                    style={{
                        fontSize: "5.46447px",
                        lineHeight: 1.25,
                        fontFamily: "sans-serif",
                        fill: "#fff",
                        fillOpacity: 1,
                        strokeWidth: 0.0640366,
                        textDecoration: "none",
                    }}
                    xmlSpace="preserve"
                    transform="translate(52.5 -74.714)"
                >
                    <tspan
                        style={{
                            fontStyle: "normal",
                            fontVariant: "normal",
                            fontWeight: 400,
                            fontStretch: "normal",
                            fontSize: "5.46447px",
                            fontFamily: "'Montserrat Alternates'",
                            fill: "#fff",
                            fillOpacity: 1,
                            strokeWidth: 0.0640366,
                            textDecoration: "none",
                        }}
                        y={89.965}
                        x={-33.743}
                    >
                        {"Technologies"}
                    </tspan>
                </text>
                <g
                    style={{
                        display: "inline",
                        fill: "#fff",
                        fillOpacity: 1,
                    }}
                >
                    <path
                        d="M84.727 110.006c1.555-3.628 10.252-42.499 2.433-43.212-5.523-.504-10.054 35.18-13.68 47.266-3.658 2.473-19.595 7.903-19.595 7.903s14.416-32.651 9.962-37.918c-4.455-5.267-17.695 31.38-19.357 39.837-3.006 1.33-15.587-1.69-27.833.569 3.91-18.554-.266-28.088 1.626-34.813 11.114-9.07 30.66-11.722 33.175-17.54 2.516-5.819-21.109 1.051-31.755 3.868 1.112-6.411 3.979-13.376 7.374-18.138 13.1-5.3 34.616-1.723 36.278-10.18 1.556-7.918-26.284-.453-31.52-.687C50.016 11.838 79.308 30.747 81.92 38.6c1.611 4.843 3.259 9.592 12.22 8.462 9.971-1.482 18.313-.54 22.952 9.675 8.87 19.537-14.126 42.956-32.365 53.27z"
                        style={{
                            fill: "#fff",
                            fillOpacity: 1,
                            stroke: "none",
                            strokeWidth: ".538641px",
                            strokeLinecap: "butt",
                            strokeLinejoin: "miter",
                            strokeOpacity: 1,
                        }}
                        transform="matrix(.13568 0 0 .13568 -.143 -1.47)"
                    />
                    <path
                        d="M116.59 34.094c-7.32-.062-19.878 4.089-26.364 7.977-.599-6.804 27.274-14.49 27.274-14.49z"
                        style={{
                            fill: "#fff",
                            fillOpacity: 1,
                            stroke: "none",
                            strokeWidth: 1,
                            strokeLinecap: "butt",
                            strokeLinejoin: "miter",
                            strokeOpacity: 1,
                        }}
                        transform="matrix(.13568 0 0 .13568 -.143 -1.47)"
                    />
                </g>
            </svg>
        </Box>
    );

    if (disabledLink) {
        return <>{logo}</>;
    }

    return <RouterLink to="/">{logo}</RouterLink>;
}
