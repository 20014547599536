import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader, CircularProgress } from '@mui/material';
// utils
import { fNumber } from '../../../../utils/formatNumber';
//
import { BaseOptionChart } from '../../../../components/chart';
import { useEffect, useState } from "react";
import { modesMap } from "../../../../pages/dashboard/Overview";
import { getVentilationBreakdown } from "../../../../utils/services";

interface ModeCount {
  mode_count: string;
  current_mode: string;
}

// ----------------------------------------------------------------------

const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important' as 'relative',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

interface Props {
  currentDevice: string;
  duration?: number;
}

export default function VentilationBreakdown(props: Props) {
  const theme = useTheme();

  const [chartData, setChartData] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [chartColors, setChartColors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchBreakdown();
  }, [props.currentDevice, props.duration]);

  const colorMap = [
      theme.palette.primary.main,
      theme.palette.chart.blue[0],
      theme.palette.chart.violet[0],
      theme.palette.chart.red[0]
  ]

  const fetchBreakdown = () => {
    if (props.duration !== undefined) {
      setIsLoading(true);
      getVentilationBreakdown(props.currentDevice, props.duration).then((json) => {
        const data = json.map((rec: ModeCount) => parseInt(rec.mode_count));
        const labels = json.map((rec: ModeCount) => modesMap[parseInt(rec.current_mode)]);
        const colors = json.map((rec: ModeCount) => colorMap[parseInt(rec.current_mode)]);
        setChartData(data);
        setChartLabels(labels);
        setChartColors(colors);
        setIsLoading(false);
      })
    }
  }

  const chartOptions = merge(BaseOptionChart(), {
    colors: chartColors,
    labels: chartLabels,
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center' },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName: string) => fNumber(seriesName),
        title: {
          formatter: (seriesName: string) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
    chart: {
      animations: {
        enabled: false,
      }
    }
  });

  return (
    <Card>
      <CardHeader title={`Ventilation - last ${props.duration} days`} />
      <ChartWrapperStyle dir="ltr">
        {isLoading ?
            <CircularProgress/>
            : <ReactApexChart type="donut" series={chartData} options={chartOptions} height={280}/>
        }
      </ChartWrapperStyle>
    </Card>
  );
}
